import React from "react"
import Navbar from "./Navbar"

const Layout = ({ children }) => {
  return (
    <div className="bg-grey-100 mx-0 md:mx-auto px-0 md:px-4 md:container">
      <Navbar />
      {children}
    </div>
  )
}

export default Layout
