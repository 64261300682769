import React from "react"
import Layout from "../components/layout"

export default function About({ data }) {
  return (
    <Layout>
      <p>Sorry, this site doesn't exist.</p>
    </Layout>
  )
}
