import { Link } from "gatsby"
import React from "react"

const Button = () => {
  return (
    <Link to="/app/profile">
      <button className="bg-blue-500 text-white font-medium py-1 px-3 rounded text-base">
        Sign Up
      </button>
    </Link>
  )
}

export default Button
